<template>
  <div class="app-container">
    <el-form label-width="120px">
      <el-form-item label="选择Excel">
        <el-upload
          ref="dragupload"
          :auto-upload="false"
          :on-success="fileUploadSuccess"
          :on-error="fileUploadError"
          :disabled="importBtnDisabled"
          :limit="1"
          :headers="mytoken"
          :action="'/api/jd/import'"
          :data="uploadData"
          name="file">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-input v-model="condition.cid1Ids" placeholder="输入一级类目id 以英文逗号分隔 例如（1,2,3）"></el-input>
          <el-input v-model="condition.cid2Ids" placeholder="输入二级类目id 以英文逗号分隔 例如（1,2,3）"></el-input>
          <el-input v-model="condition.cid3Ids" placeholder="输入三级类目id 以英文逗号分隔 例如（1,2,3）"></el-input>

          <el-button
            :loading="loading"
            style="margin-left: 10px;"
            size="small"
            type="success"
            @click="submitUpload">上传到服务器</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>
  
  <script>
  export default {
    data() { //定义变量和初始值
      return{
        importBtnDisabled: false, // 按钮是否禁用,
        loading: false,
        condition:{
          cid1Ids:'',
          cid2Ids:'',
          cid3Ids:''
        }
      }
    },
    computed: {
    mytoken() {
      return {
        "x-auth-token": localStorage.getItem("token"),
      };
    },
    uploadData() {
      return {
        cid1Ids: this.condition.cid1Ids,
        cid2Ids: this.condition.cid2Ids,
        cid3Ids: this.condition.cid3Ids
      };
    },
    
  },
    created() { //页面渲染之前执行
     
    },
    methods: {
     //点击按钮上传文件到接口里面
     submitUpload() {
            this.importBtnDisabled = true
            this.loading = true
            // js: document.getElementById("upload").submit()
            if (this.condition.cid1Ids ||this.condition.cid12ds||this.condition.cid3Ids ) {
         this.$refs.dragupload.submit();
            }
        },
        //上传成功
        fileUploadSuccess(response) {
          console.log(response)
            //提示信息
            this.loading = false
            this.$message({
                type: 'success',
                message: '添加成功'
            })
           if (response.code === 1000) {
            const link = document.createElement("a");
            link.href = response.data;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        },
        //上传失败
        fileUploadError() {
            this.loading = false
            this.$message({
                type: 'error',
                message: '添加失败'
            })
        }
    }
  }
  </script>
  
  
